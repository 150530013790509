@import '~reset-css';
@import url('https://fonts.googleapis.com/css?family=Karma|Roboto');

.component {
    align-items: center;
    background-color: #fdfdfd;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    height: 100vh;
    justify-content: center;
}

.component,
.link {
    color: #333;
}

.title {
    font-family: 'Karma', serif;
    font-size: 15vw;
    overflow: hidden;
}

.subtitle {
    font-size: 2.5vw;
    text-transform: uppercase;
}

.link {
    text-decoration: none;
}

.link:hover {
    color: #f38630;
}
