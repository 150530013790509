@import url(https://fonts.googleapis.com/css?family=Karma|Roboto);
.styles_component__3LSDK {
    -webkit-align-items: center;
            align-items: center;
    background-color: #fdfdfd;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-family: 'Roboto', sans-serif;
    height: 100vh;
    -webkit-justify-content: center;
            justify-content: center;
}

.styles_component__3LSDK,
.styles_link__3__FL {
    color: #333;
}

.styles_title__wOgY6 {
    font-family: 'Karma', serif;
    font-size: 15vw;
    overflow: hidden;
}

.styles_subtitle__bj5VZ {
    font-size: 2.5vw;
    text-transform: uppercase;
}

.styles_link__3__FL {
    text-decoration: none;
}

.styles_link__3__FL:hover {
    color: #f38630;
}

